import axios from "axios";
//import { history } from "react-router-dom";

const axiosConfig = () => {
	axios.defaults.timeout = 5000;

	axios.interceptors.response.use(
		undefined, // If there's no error, we won't do anything.
		(err) => {
			if (err.response.status === 401) {
				err.isUnauthorized = true;
			}

			return Promise.reject(err);
		}
	);
};

export default axiosConfig;