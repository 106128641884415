const axiosError = (sysMsg, setLoading) => err => {
	if (err.response && err.response.data) {
		const sysMsgData = err.response.data;
		if (sysMsgData.severity && sysMsgData.message)
			sysMsg(err.response.data);
		else
			sysMsg({
				severity: "error",
				message: "V aplikaci došlo k neznámé chybě."
			});
	}
	else {
		sysMsg({
			severity: "error",
			message: "Nelze se připojit k serveru. Buď došlo k výpadku, nebo nejste připojeni k internetu. :("
		});
	}

	if (typeof setLoading === "function")
		setLoading(false);
};

export default axiosError;