import React from 'react';
import PlusIcon from "mdi-react/PlusIcon";

const ButtonAdd = ({ isTransparent = false, label = "Přidat", onClick}) => {
	let buttonClass = "button";

	if (isTransparent)
		buttonClass += " button--transparent";

	return (
		<button className={buttonClass} onClick={onClick}>
			<PlusIcon />
			{label}
		</button>
	);
}

export default ButtonAdd;