import React from 'react';
import Dropzone from 'react-dropzone';
import axios from "axios";

import ButtonAdd from './ButtonAdd';
import axiosError from '../utils/axiosError';

class FileDropzone extends React.Component {
	onDrop = (files) => {
		this.props.setLoading(true);

		const axiosHeaders = {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		};
		let formData = new FormData();
		formData.append("files", files[0]);
		formData.append("subcategory", this.props.subcategory);

		axios
			.post('/api/documents', formData, axiosHeaders)
			.then((res) => {
				this.props.setSysMsg(res.data);
				this.props.refreshDocuments();
				this.props.setLoading(false);
			})
			.catch(axiosError(this.props.setSysMsg, this.props.setLoading));
	};

	render() {
		return (
				<Dropzone
					multiple={false}
					maxSize={1024 * 1024 * 1024 * 50} // Max 50 MB.
					name="adddocument"
					onDrop={this.onDrop}
				>
					{({getRootProps, getInputProps}) => (
						<div {...getRootProps()} className="dropzone">
							<input {...getInputProps()} />
							<ButtonAdd isTransparent={true} label="Přidat soubor"/>
						</div>
					)}
				</Dropzone>
		);
	}
}

export default FileDropzone;