import React from 'react';

const NotFound = () => {
	return (
		<div className="box">
			<div className="box__paper box__paper--wide">
				<h1 className="h-text-center">Stránka nenalezena</h1>
				<div className="h-text-center">
					Je nám to líto, ale tato stránka neexistuje.
				</div>
			</div>
		</div>
	);
};

export default NotFound;