import React from 'react';
import axios from 'axios';

import { setAuthToken } from "../utils/jwtToken";
import axiosError from '../utils/axiosError';

class User extends React.Component {
	state = {
		name: this.props.token.name,
		password: ""
	};

	onNameSubmit = (e) => {
		e.preventDefault();
		const req = {
			name: this.state.name
		};

		axios
			.put("/api/user/" + this.props.token.id, req)
			.then(res => {
				this.props.setSysMsg(res.data);
				const decodedToken = setAuthToken(res.data.token);
				this.props.setToken(decodedToken);
			})
			.catch(axiosError(this.props.setSysMsg));
	};

	onPasswordSubmit = (e) => {
		e.preventDefault();
		const req = {
			password: this.state.password
		};

		axios
			.put("/api/user/" + this.props.token.id, req)
			.then(res => {
				this.props.setSysMsg(res.data);
				const decodedToken = setAuthToken(res.data.token);
				this.props.setToken(decodedToken);
			})
			.catch(axiosError(this.props.setSysMsg));
	};

	onChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		this.setState({
			[name]: value
		});
	};

	render() {
		return (
			<div className="box">
				<div className="box__paper">
				<h1 className="box__heading">Nastavení</h1>
				{this.props.token.id === 0 ? <div className="h-text-center">Nastavení superadmina nelze měnit.</div> : (
					<>
						<form onSubmit={this.onNameSubmit}>
							<label
								htmlFor="name">Změnit jméno/název firmy</label>
							<input
								className="h-full-width"
								onChange={this.onChange}
								defaultValue={this.state.name}
								type="text"
								name="name"
								id="name" />
							<button
								type="submit"
								className="button h-mt-small h-full-width">Uložit</button>
						</form>
						<form onSubmit={this.onPasswordSubmit}>
							<label
								htmlFor="password">Nové heslo</label>
							<input
								className="h-full-width"
								onChange={this.onChange}
								type="password"
								name="password"
								id="password"
								/>
							<button
								type="submit"
								className="button h-mt-small h-full-width">Uložit</button>
						</form>
						</>
					)}
				</div>
			</div>
		);
	}
}

export default User;