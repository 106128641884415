import React from "react";
import { ReactComponent as Logo } from "../logo.svg";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

import LoggedUser from "./LoggedUser";

const Header = ({ setSysMsg, setToken, token, history }) => {
	const isLoggedIn = token && token.name && token.role;

	return (
		<div className="header">
			{isLoggedIn ? (
				<Link to="/">
					<Logo className="header__logo" />
				</Link>
			) : (
				<Logo className="header__logo" />
			)}
			<div className="header_user">
				{isLoggedIn && <LoggedUser token={token} setSysMsg={setSysMsg} history={history} setToken={setToken} />}
			</div>
		</div>
	);
}

export default withRouter(Header);