import React from 'react';
import axios from 'axios';
import DeleteOutlineIcon from "mdi-react/DeleteOutlineIcon";
import PencilOutlineIcon from "mdi-react/PencilOutlineIcon";
import PlusIcon from "mdi-react/PlusIcon";
import MinusIcon from "mdi-react/MinusIcon";

import Document from "./Document";
import ButtonIcon from './ButtonIcon';
import FileDropzone from './FileDropzone';
import axiosError from '../utils/axiosError';
import Modal from './Modal';
import ConfirmModal from './ConfirmModal';

class Subcategory extends React.Component {
	state = {
		isOpen: this.props.isOpen,
		isEditModalOpen: false,
		editSubcategory: "",
		isModalClosing: false,
		isConfirmModalOpen: false
	};

	onDeleteOpen = (e) => {
		e.preventDefault();
		this.setState({
			isConfirmModalOpen: true
		});
	};

	onDeleteConfirm = (e) => {
		e.preventDefault();
		if (this.state.isModalClosing) // Closing modal, do nothing.
		return false;

		const categoryId = this.props.categoryId;
		const subcategoryId = this.props._id;

		axios
			.delete(`/api/categories/${categoryId}/subcategories/${subcategoryId}`)
			.then(res => {
				this.props.setSysMsg(res.data);
				this.props.refreshDocuments();
			})
			.catch(axiosError(this.props.setSysMsg));
	};

	onDeleteClose = (e) => {
		this.setState({ isConfirmModalOpen: false });
	};

	itemClasses = () => {
		if (this.state.isOpen)
			return "subcategory subcategory--active";
		else
			return "subcategory";
	};

	onHeaderClick = (e) => {
		this.setState((prevState) => ({
			isOpen: !prevState.isOpen
		}));
	};

	onControlsClick = (e) => {
		e.stopPropagation();
	};

	onEditSubmit = (e) => {
		e.preventDefault();
		if (this.state.isModalClosing) // Closing modal, do nothing.
			return false;

		this.setState({
			isModalClosing: true
		});

		const categoryId = this.props.categoryId;
		const subcategoryId = this.props._id;
		const req = {
			name: this.state.editSubcategory
		};

		axios
			.put(`/api/categories/${categoryId}/subcategories/${subcategoryId}`, req)
			.then(res => {
				this.props.setSysMsg(res.data);
				this.props.refreshDocuments();
				this.onEditClose();
			})
			.catch(axiosError(this.props.setSysMsg));
	};

	onEditOpen = (e) => {
		e.preventDefault();
		this.setState({
			isEditModalOpen: true,
			editSubcategory: this.props.name
		});
	};

	onEditClose = (e) => {
		this.setState({
			isEditModalOpen: false,
			isModalClosing: true
		});
	};

	onModalExited = (e) => {
		this.setState({
			isModalClosing: false
		});
	};

	onChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		this.setState({
			[name]: value
		});
	};

	render() {
		if (this.props.role !== "admin" && this.props.documents.length === 0) // Don't render empty subcategories to guests.
			return null;

		return (
			<>
			<div className={this.itemClasses()}>
				<div className="subcategory__header" onClick={this.onHeaderClick}>
					<h2 className="subcategory__heading">
						<span className="subcategory__heading-text">{this.props.name}</span>
						{this.props.role === "admin" &&
							<span className="subcategory__heading-controls" onClick={this.onControlsClick}>
								<ButtonIcon Icon={PencilOutlineIcon} onClick={this.onEditOpen} title="Přejmenovat podkategorii" />
								<ButtonIcon Icon={DeleteOutlineIcon} onClick={this.onDeleteOpen} title="Smazat podkategorii" />
							</span>
						}
						<PlusIcon className="subcategory__plus" />
						<MinusIcon className="subcategory__minus" />
					</h2>
				</div>
				<div className="subcategory__content">
					{this.props.documents.map(document => (
						<Document
							{...document}
							role={this.props.role}
							key={document._id}
							refreshDocuments={this.props.refreshDocuments}
							setSysMsg={this.props.setSysMsg}
							setLoading={this.props.setLoading} />
					))}
					{this.props.role === "admin" &&
						<div className="h-text-center">
							<FileDropzone
								setSysMsg={this.props.setSysMsg}
								subcategory={this.props._id}
								refreshDocuments={this.props.refreshDocuments}
								setLoading={this.props.setLoading}
							/>
						</div>
					}
				</div>
			</div>
			{this.props.role === "admin" &&
				<>
					<Modal
						open={this.state.isEditModalOpen}
						onClose={this.onEditClose}
						onExited={this.onModalExited}
						title="Upravit podkategorii">
						<form action="" onSubmit={this.onEditSubmit}>
							<label htmlFor="editSubcategory">Název podkategorie</label>
							<input type="text" name="editSubcategory" id="editSubcategory" className="h-full-width" onChange={this.onChange} defaultValue={this.state.editSubcategory} />
							<div className="modal__button">
								<button type="submit" className="button" disabled={this.state.isEditSubmitDisabled}>Uložit</button>
							</div>
						</form>
					</Modal>
					<ConfirmModal
						open={this.state.isConfirmModalOpen}
						onConfirm={this.onDeleteConfirm}
						onClose={this.onDeleteClose}
						onExited={this.onModalExited}
						title="Smazat podkategorii"
						message="Opravdu chcete smazat podkategorii včetně všech souborů? Tuto akci nelze vrátit zpět."
					/>
				</>
			}
			</>
		);
	}
}

Subcategory.defaultProps = {
	name: "Podkategorie beze jména",
	documents: [],
	isOpen: false
}

export default Subcategory;