import React from 'react';
import axios from 'axios';
import DeleteOutlineIcon from "mdi-react/DeleteOutlineIcon";
import AccountKeyOutlineIcon from "mdi-react/AccountKeyOutlineIcon";

import ButtonAdd from './ButtonAdd';
import ButtonIcon from './ButtonIcon';
import axiosError from '../utils/axiosError';
import Modal from './Modal';

class Users extends React.Component {
	state = {
		users: [],
		isAddModalOpen: false,
		isModalClosing: false
	};

	onDelete = userId => (e) => {
		e.preventDefault();

		axios
			.delete('/api/user/' + userId)
			.then(res => {
				this.props.setSysMsg(res.data);
				this.refreshUsers();
			})
			.catch(axiosError(this.props.setSysMsg));
	};

	onAddOpen = (e) => {
		e.preventDefault();
		this.setState({ isAddModalOpen: true });
	};

	onAddClose = (e) => {
		this.setState({ isAddModalOpen: false });
	};

	onAddSubmit = (e) => {
		e.preventDefault();
		if (this.state.isModalClosing) // Closing modal, do nothing.
			return false;

		this.setState({
			isModalClosing: true
		});

		const req = {
			name: this.state.addName,
			email: this.state.addEmail,
			role: this.state.addRole || "guest"
		};

		axios
			.post("/api/user/", req)
			.then(res => {
				this.props.setSysMsg(res.data);
				this.refreshUsers();
				this.onAddClose();
			})
			.catch(axiosError(this.props.setSysMsg));
	};

	onChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		this.setState({
			[name]: value
		});
	};

	onModalExited = (e) => {
		this.setState({
			isModalClosing: false
		});
	};

	refreshUsers = () => {
		axios
			.get("/api/user/all")
			.then(res => {
				this.setState({
					users: res.data
				});
			})
			.catch(axiosError(this.props.setSysMsg));
	};

	componentDidMount() {
		if (this.props.token === null) {
			this.props.history.push('/login');
		} else {
			this.refreshUsers();
		}
	}

	render() {
		return (
			<>
				<div className="box">
					<div className="box__paper box__paper--wide">
						<h1 className="h-text-center">Správa uživatelů</h1>
						{this.state.users.length > 15 &&
						<div className="h-text-center">
							<ButtonAdd isTransparent={true} label="Přidat uživatele" onClick={this.onAddOpen} />
						</div>
						}
						<div className="list">
							{this.state.users.map(user => (
								<div className="list__item" key={user._id}>
									{user.role === "admin" && <span title="Administrátor"><AccountKeyOutlineIcon size={16} className="h-icon" /></span>}
									{user.email}, {user.name}
									<ButtonIcon Icon={DeleteOutlineIcon} onClick={this.onDelete(user._id)} title="Smazat uživatele" />
								</div>
							))}
						</div>
						<div className="h-text-center">
							<ButtonAdd isTransparent={true} label="Přidat uživatele" onClick={this.onAddOpen} />
						</div>
					</div>
				</div>
				<Modal
					open={this.state.isAddModalOpen}
					onClose={this.onAddClose}
					onExited={this.onModalExited}
					title="Přidat uživatele">
					<form action="" onSubmit={this.onAddSubmit}>
						<label htmlFor="addEmail">E-mail</label>
						<input type="text" name="addEmail" id="addEmail" className="h-full-width" onChange={this.onChange} />
						<label htmlFor="addName">Jméno/Název firmy</label>
						<input type="text" name="addName" id="addName" className="h-full-width" onChange={this.onChange} />
						<label htmlFor="addRole">Oprávnění</label>
						<select name="addRole" id="addRole" className="h-full-width" defaultValue="guest" onChange={this.onChange}>
							<option value="guest">Běžný uživatel</option>
							<option value="admin">Administrátor</option>
						</select>
						<div className="modal__button">
							<button type="submit" className="button">Přidat</button>
						</div>
					</form>
				</Modal>
			</>
		);
	}
}

export default Users;