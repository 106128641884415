import React from 'react';
import axios from 'axios';

import axiosError from '../utils/axiosError';
import ButtonIcon from "./ButtonIcon";
import ConfirmModal from './ConfirmModal';

import DeleteOutlineIcon from "mdi-react/DeleteOutlineIcon";
import FileDefaultIcon from "mdi-react/FileIcon";

/*

@TODO: Ikony nejsou v SVG horizontálně vycentrované (chyba npm balíčku), takž se špatně zarovnávají. Prozatím tedy zakomentováno a použita defaultně jediná ikona souboru.

import FilePdfIcon from "mdi-react/FilePdfIcon";
import FileWordIcon from "mdi-react/FileWordIcon";
import FileExcelIcon from "mdi-react/FileExcelIcon";
import FileImageIcon from "mdi-react/FileImageIcon";

// Ext to icon conversion table.
const extToIcon = {
	pdf: FilePdfIcon,
	xls: FileExcelIcon,
	xlsx: FileExcelIcon,
	doc: FileWordIcon,
	docx: FileWordIcon,
	jpg: FileImageIcon,
	jpeg: FileImageIcon,
	png: FileImageIcon,
	default: FileDefaultIcon,
}


// Returns file ext.
const getExt = (name) => {
	const nameParts = name.split(".");
	if (nameParts.length)
		return nameParts[nameParts.length - 1]; // Last index of array.
	else
		return "default";
};

// Renders file icon according to ext.
const FileIcon = ({ fileName = "file.default" }) => {
	let ext = getExt(fileName);
	if (!extToIcon[ext])
		ext = "default";

	const CurrentIcon = extToIcon[ext];
	return <CurrentIcon className="document__icon" />;
};
*/

// Returns filename from uri.
const getFilename = (uri) => {
	const uriParts = uri.split("/");
	if (uriParts.length)
		return uriParts[uriParts.length - 1]; // Last index of array.
	else
		return "file.default";
};

class Document extends React.Component {
	state = {
		isAddModalOpen: false,
		addDocument: "",
		isModalClosing: false,
		isConfirmModalOpen: false
	};

	onDeleteOpen = (e) => {
		e.preventDefault();
		this.setState({
			isConfirmModalOpen: true
		});
	};

	onDeleteConfirm = (e) => {
		e.preventDefault();
		if (this.state.isModalClosing) // Closing modal, do nothing.
		return false;

		this.setState({ isModalClosing: true });

		axios
			.delete('/api/documents/' + this.props._id)
			.then(res => {
				this.props.setSysMsg(res.data);
				this.props.refreshDocuments();
			})
			.catch(axiosError(this.props.setSysMsg));
	};

	onDeleteClose = (e) => {
		this.setState({ isConfirmModalOpen: false });
	};

	onDownload = (e) => {
		e.preventDefault();
		axios({
			url: e.target.getAttribute("href"),
			method: 'GET',
			responseType: 'blob', // important
		})
		.then((response) => {
			const mime = response.headers.mime || "application/octet-stream";
			const filename = getFilename(this.props.uri);
			const blob = new Blob([response.data], { type: mime });

			if (typeof window.navigator.msSaveBlob !== 'undefined')
				window.navigator.msSaveBlob(blob, filename);
			else {
				const blobURL = window.URL.createObjectURL(blob);
				const tempLink = document.createElement('a');

				tempLink.style.display = 'none';
				tempLink.href = blobURL;
				tempLink.setAttribute('download', filename);

				if (typeof tempLink.download === 'undefined') {
						tempLink.setAttribute('target', '_blank');
				}

				document.body.appendChild(tempLink);
				tempLink.click();
				document.body.removeChild(tempLink);
				window.URL.revokeObjectURL(blobURL);
			}
		})
		.catch((err) => {
			if (err.response.status === "404")
				this.props.setSysMsg({severity: "error", message: "Dokument je bohužel momentálně nedostupný."});
			else
				this.props.setSysMsg({severity: "error", message: "V aplikaci došlo k neznámé chybě."});
		});
	};

	render() {
		return (
			<div className="document">
				<a href={this.props.uri} className="document__link" onClick={this.onDownload}>
					{/* @TODO: <FileIcon fileName={fileName} /> // Icons are not horizontaly centered :( */}
					<FileDefaultIcon className="document__icon" />
					{getFilename(this.props.uri)}
				</a>
				{this.props.role === "admin" &&
					<>
						<span className="document__controls">
							<ButtonIcon
								Icon={DeleteOutlineIcon}
								size={16}
								onClick={this.onDeleteOpen}
								title="Smazat dokument" />
						</span>
						<ConfirmModal
							open={this.state.isConfirmModalOpen}
							onConfirm={this.onDeleteConfirm}
							onClose={this.onDeleteClose}
							onExited={this.onModalExited}
							title="Smazat dokument"
							message="Opravdu chcete dokument smazat? Tuto akci nelze vrátit zpět."
						/>
					</>
				}
			</div>
		);
	}
}

export default Document;