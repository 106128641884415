import React from 'react';
import axios from 'axios';

import Category from "./Category";
import ButtonAdd from './ButtonAdd';
import Modal from './Modal';
import axiosError from '../utils/axiosError';

class ListAll extends React.Component {
	state = {
		isAddModalOpen: false,
		addCategory: ""
	};

	onAddOpen = (e) => {
		e.preventDefault();
		this.setState({ isAddModalOpen: true });
	};

	onAddSubmit = (e) => {
		e.preventDefault();
		const req = {
			name: this.state.addCategory
		};

		axios
			.post('/api/categories', req)
			.then(res => {
				this.props.setSysMsg(res.data);
				this.refreshDocuments();
				this.onAddClose();
			})
			.catch(axiosError(this.props.setSysMsg));
	};

	onAddClose = (e) => {
		this.setState({ isAddModalOpen: false });
	};

	onChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		this.setState({
			[name]: value
		});
	};

	refreshDocuments = () => {
		axios
			.get("/api/documents")
			.then(res => {
				this.props.setCategories(res.data);
			})
			.catch(axiosError(this.props.setSysMsg));
	};

	componentDidMount() {
		if (this.props.token === null) {
			this.props.history.push('/login');
		} else {
			this.refreshDocuments();
		}
	}

	render() {
		return (
			<>
				{this.props.categories.map(category => (
					<Category
						{...category}
						role={this.props.token && this.props.token.role}
						key={category._id}
						refreshDocuments={this.refreshDocuments}
						setSysMsg={this.props.setSysMsg}
						setLoading={this.props.setLoading} />
				))}
				{this.props.token && this.props.token.role === "admin" &&
					<>
						<div className="h-text-center">
							<ButtonAdd isTransparent={true} label="Přidat kategorii" onClick={this.onAddOpen} />
						</div>
						<Modal open={this.state.isAddModalOpen} onClose={this.onAddClose} title="Přidat kategorii">
							<form action="" onSubmit={this.onAddSubmit}>
								<label htmlFor="addCategory">Název kategorie</label>
								<input type="text" name="addCategory" id="addCategory" className="h-full-width" onChange={this.onChange} />
								<div className="modal__button">
									<button type="submit" className="button">Přidat</button>
								</div>
							</form>
						</Modal>
					</>
				}
			</>
		);
	}
}

export default ListAll;