import React from 'react';
import ResponsiveModal from 'react-responsive-modal';

const Modal = ({ open, onClose, onExited, title, children }) => {
	return (
		<ResponsiveModal
			open={open}
			onClose={onClose}
			onExited={onExited}
			center
			classNames={{
				modal: "modal",
				closeButton: "modal__close"
			}}
			closeOnOverlayClick={false}>
			<div className="modal__header">{title}</div>
			{children}
		</ResponsiveModal>
	);
};

export default Modal;