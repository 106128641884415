import React from 'react';
import axios from 'axios';
import axiosError from '../utils/axiosError';
import { format as dateFormat } from 'date-fns';

class LoginHistory extends React.Component {
	state = {
		logins: []
	};

	refreshLogins = () => {
		axios
			.get("/api/user/history")
			.then(res => {
				const logins = res.data.map(log => {
					const loginDate = new Date(log.time);

					return {
						id: log._id,
						email: log.email,
						date: dateFormat(loginDate, "dd.MM.yyyy"),
						time: dateFormat(loginDate, "HH:mm:ss")
					};
				});
				this.setState({
					logins
				});
			})
			.catch(axiosError(this.props.setSysMsg));
	};

	componentDidMount() {
		if (this.props.token === null) {
			this.props.history.push('/login');
		} else {
			this.refreshLogins();
		}
	}

	render() {
		return (
			<>
				<div className="box">
					<div className="box__paper box__paper--wide">
						<h1 className="h-text-center">Historie přihlášení</h1>
						<div className="list">
							<table className="table">
								<thead>
									<tr>
										<th className="h-text-left">E-mail</th>
										<th className="h-text-right">Datum</th>
										<th className="h-text-right">Čas</th>
									</tr>
								</thead>
								<tbody>
									{this.state.logins.map(login => (
										<tr key={login.id}>
											<td>{login.email}</td>
											<td className="h-text-right">{login.date}</td>
											<td className="h-text-right">{login.time}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default LoginHistory;