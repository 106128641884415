import React from 'react';
import Modal from './Modal';

const ConfirmModal = (props) => {

	return (
		<Modal
			open={props.open}
			onClose={props.onClose}
			onExited={props.onExited}
			title={props.title}>
			<form action="" onSubmit={props.onConfirm}>
				<div>{props.message}</div>
				<div className="modal__button">
					<button type="submit" className="button">Potvrdit</button>
					<button type="button" className="button button--transparent" onClick={props.onClose}>Zrušit</button>
				</div>
			</form>
		</Modal>
	);
}

export default ConfirmModal;