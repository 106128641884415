import axios from 'axios';
import jwtDecode from 'jwt-decode';

/**
 * Returns JWT token payload if token is found in local storage and is valid.
 * Returns null, if not.
 */
const getAuthToken = () => {
	let token = localStorage.getItem("jwtToken");
	if (token) {
		const currentTime = Date.now() / 1000;
		const decodedToken = jwtDecode(localStorage.jwtToken);
		if (decodedToken.exp > currentTime) {
			axios.defaults.headers.common['Authorization'] = token;
			return decodedToken;
		}
		else
			return null;
	}
	else
		return null;
};

const setAuthToken = token => {
	if (token) {
		axios.defaults.headers.common['Authorization'] = token; // Apply to every axios request
		localStorage.setItem("jwtToken", token);
		return jwtDecode(token);
	}
};

const removeAuthToken = () => {
	// Delete auth header
	delete axios.defaults.headers.common['Authorization'];
	localStorage.removeItem("jwtToken");
};

export {
	getAuthToken,
	setAuthToken,
	removeAuthToken
};