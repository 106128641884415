import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Loader from "react-loader-spinner";

import { getAuthToken } from "./utils/jwtToken";
import ListAll from "./components/ListAll";
import Login from "./components/Login";
import SysMsg from "./components/SysMsg";
import axiosConfig from "./utils/axiosConfig";
import Header from './components/Header';
import User from './components/User';
import Users from './components/Users';
import NotFound from './components/NotFound';
import LoginHistory from './components/LoginHistory';

axiosConfig();

class App extends Component {
	state = {
		token: getAuthToken(),
		sysMsg: {},
		categories: [],
		isLoading: false
	};

	setToken = (token) => {
		this.setState({ token });
	};

	setSysMsg = (sysMsg) => {
		this.setState({ sysMsg });
	};

	clearSysMsg = () => {
		this.setState({ sysMsg: {} });
	};

	setCategories = (categories) => {
		this.setState({ categories });
	};

	setLoading = (state) => {
		this.setState({ isLoading: state });
	};

	render() {
		return (
			<BrowserRouter>
				<React.Fragment>
					<Header token={this.state.token} setSysMsg={this.setSysMsg} setToken={this.setToken} />
					<div className="layout">
						<Switch>
							<Route
								exact path="/"
								render={(props) =>
									<ListAll
										{...props}
										{...this.state}
										setSysMsg={this.setSysMsg}
										setCategories={this.setCategories}
										openModal={this.openModal}
										clearSysMsg={this.clearSysMsg}
										setLoading={this.setLoading}
									/>}
							/>
							<Route
								exact path="/login"
								render={(props) =>
									<Login
										{...props}
										{...this.state}
										setToken={this.setToken}
										setSysMsg={this.setSysMsg}
										clearSysMsg={this.clearSysMsg}
										setLoading={this.setLoading}
									/>}
							/>
							<Route
								exact path="/user"
								render={(props) =>
									<User
										{...props}
										{...this.state}
										setSysMsg={this.setSysMsg}
										setToken={this.setToken}
										setLoading={this.setLoading}
									/>}
							/>
							<Route
								exact path="/users"
								render={(props) =>
									<Users
										{...props}
										{...this.state}
										setSysMsg={this.setSysMsg}
										setLoading={this.setLoading}
									/>}
							/>
							<Route
								exact path="/loginHistory"
								render={(props) =>
									<LoginHistory
										{...props}
										{...this.state}
										setSysMsg={this.setSysMsg}
										setLoading={this.setLoading}
									/>}
							/>
							<Route
								render={(props) =>
									<NotFound
										{...props}
										{...this.state}
										setSysMsg={this.setSysMsg}
										setLoading={this.setLoading}
									/>}
							/>
						</Switch>
					</div>
					<SysMsg
						sysMsg={this.state.sysMsg}
						clearSysMsg={this.clearSysMsg}
					/>
					{this.state.isLoading &&
					<div className="loader">
						<Loader type="Grid" color="#213b6a" height={80} width={80} />
					</div>
					}
				</React.Fragment>
			</BrowserRouter>
		);
	}
}

export default App;
