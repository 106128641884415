import React from 'react';
import Close from "mdi-react/CloseIcon";

const SysMsg = ({ sysMsg, clearSysMsg }) => {
	if (!sysMsg.severity)
		return null;

	return (
		<div className="sysmsg">
			<div className={`sysmsg__box sysmsg__box--${sysMsg.severity}`}>
				<div>
					{sysMsg.message}
				</div>
				<div className="sysmsg__close" onClick={clearSysMsg}>
					<Close size={16} />
				</div>
			</div>
		</div>
	);
};

export default SysMsg;