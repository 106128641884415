import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { removeAuthToken } from "../utils/jwtToken";

const LoggedUser = ({ token, setSysMsg, history, setToken }) => {
	const logout = (e) => {
		e.preventDefault();
		removeAuthToken();
		setToken(null);
		setSysMsg({
			severity: "success",
			message: "Odhlášení úspěšné."
		});
		history.push('/login');
	};

	return (
		<>
			{token.role === "admin" &&
				<>
				<Link to="/users" className="header__user">Správa uživatelů</Link>
				<Link to="/loginHistory" className="header__user">Historie loginů</Link>
				</>
			}
			<Link to="/user" className="header__user">{token.name}</Link>
			<Link to="/login" onClick={logout}>Odhlásit se</Link>
		</>
	);
};

export default withRouter(LoggedUser);