import React from 'react';
import axios from 'axios';

import { setAuthToken } from "../utils/jwtToken";
import axiosError from '../utils/axiosError';
import Modal from './Modal';

class Login extends React.Component {
	state = {
		email: "",
		password: "",
		isRegisterModalOpen: false,
		isRenewModalOpen: false,
		isModalClosing: false
	};

	componentDidMount() {
		if (this.props.token) {
			this.props.history.push('/');
		}
	}

	onSubmit = (e) => {
		e.preventDefault();
		const req = {
			email: this.state.email,
			password: this.state.password
		};

		axios
			.post('/api/user/login', req)
			.then(res => {
				const decodedToken = setAuthToken(res.data.token);
				this.props.setToken(decodedToken);
				this.props.clearSysMsg();
				this.props.history.push('/');
			})
			.catch(axiosError(this.props.setSysMsg));
	};
	onRenew = (e) => {
		e.preventDefault();
		const req = {
			email: this.state.renewEmail
		};

		axios
			.post('/api/user/password', req)
			.then(res => {
				this.props.setSysMsg(res.data);
				this.onRenewClose();
			})
			.catch((err) => {
				axiosError(this.props.setSysMsg)(err);
				this.setState({ isModalClosing: false });
			});
	};
	onChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		this.setState({
			[name]: value
		});
	};

	onRegisterOpen = (e) => {
		e.preventDefault();
		this.setState({ isRegisterModalOpen: true });
	};

	onRenewOpen = (e) => {
		e.preventDefault();
		this.setState({ isRenewModalOpen: true });
	};

	onRegisterClose = (e) => {
		this.setState({ isRegisterModalOpen: false });
	};

	onRenewClose = (e) => {
		this.setState({ isRenewModalOpen: false });
	};

	onRegisterSubmit = (e) => {
		e.preventDefault();
		if (this.state.isModalClosing) // Closing modal, do nothing.
			return false;

		this.setState({ isModalClosing: true });

		const req = {
			name: this.state.registerName,
			email: this.state.registerEmail
		};

		axios
			.post("/api/user/register", req)
			.then(res => {
				this.props.setSysMsg(res.data);
				this.onRegisterClose();
			})
			.catch((err) => {
				axiosError(this.props.setSysMsg)(err);
				this.setState({ isModalClosing: false });
			});
	};

	onModalExited = (e) => {
		this.setState({ isModalClosing: false });
	};

	render() {
		return (
			<>
			<div className="box">
				<div className="box__paper">
				<h1 className="box__heading">Přihlaste se prosím</h1>
				<form onSubmit={this.onSubmit}>
					<label
						htmlFor="loginEmail">E-mail</label>
					<input
						className="h-full-width"
						onChange={this.onChange}
						value={this.state.email}
						type="text"
						name="email"
						id="loginEmail" />
					<label
						htmlFor="loginPassword">Heslo</label>
					<input
						className="h-full-width"
						onChange={this.onChange}
						value={this.state.password}
						type="password"
						name="password"
						id="loginPassword" />
					<button
						type="submit"
						className="button h-mt-big h-full-width">Login</button>
					<div className="box__renew">
						<div className="list list--nomargin">
							<div className="list__item">
								<a href="/" onClick={this.onRegisterOpen}>Požádat o vystavení přístupů</a>
							</div>
							<div className="list__item">
								<a href="/" onClick={this.onRenewOpen}>Požádat o znovuzaslání přístupů</a>
							</div>
						</div>
					</div>
				</form>
				</div>
			</div>
			<Modal
				open={this.state.isRegisterModalOpen}
				onClose={this.onRegisterClose}
				onExited={this.onModalExited}
				title="Registrace">
				<div>
					Žádost k registraci musí nejprve schválit jeden z našich pracovníků. Následně vám na zadaný e-mail budou odeslány přihlašovací údaje.
				</div>
				<form action="" onSubmit={this.onRegisterSubmit}>
					<label htmlFor="registerEmail">E-mail</label>
					<input type="text" name="registerEmail" id="registerEmail" className="h-full-width" onChange={this.onChange} defaultValue={this.state.registerEmail} />
					<label htmlFor="registerName">Jméno/Název firmy</label>
					<input type="text" name="registerName" id="registerName" className="h-full-width" onChange={this.onChange} defaultValue={this.state.registerName} />
					<div className="modal__button">
						<button type="submit" className="button">Odeslat žádost</button>
					</div>
				</form>
			</Modal>
			<Modal
				open={this.state.isRenewModalOpen}
				onClose={this.onRenewClose}
				onExited={this.onModalExited}
				title="Obnova hesla">
				<div>
					Na zadaný e-mail vám bude zasláno nové heslo.
				</div>
				<form onSubmit={this.onRenew}>
					<label htmlFor="renewEmail">E-mail</label>
					<input type="text" name="renewEmail" id="renewEmail" className="h-full-width" onChange={this.onChange} />
					<div className="modal__button">
						<button type="submit" className="button">Odeslat</button>
					</div>
				</form>
			</Modal>
			</>
		);
	}
}

export default Login;
