import React from 'react';
//import { Link } from 'react-router-dom';
//import { PropTypes } from 'prop-types';
import axios from 'axios';
import DeleteOutlineIcon from "mdi-react/DeleteOutlineIcon";
import PencilOutlineIcon from "mdi-react/PencilOutlineIcon";

import Subcategory from "./Subcategory";
import ButtonAdd from './ButtonAdd';
import ButtonIcon from './ButtonIcon';
import axiosError from '../utils/axiosError';
import Modal from './Modal';
import ConfirmModal from './ConfirmModal';

class Category extends React.Component {
	state = {
		isEditModalOpen: false,
		isAddModalOpen: false,
		editCategory: "",
		isModalClosing: false,
		isConfirmModalOpen: false
	};

	onDeleteOpen = (e) => {
		e.preventDefault();
		this.setState({
			isConfirmModalOpen: true
		});
	};

	onDeleteConfirm = (e) => {
		e.preventDefault();
		if (this.state.isModalClosing) // Closing modal, do nothing.
		return false;

		this.setState({ isModalClosing: true });

		const categoryId = this.props._id;
		axios
			.delete('/api/categories/' + categoryId)
			.then(res => {
				this.props.setSysMsg(res.data);
				this.props.refreshDocuments();
				this.onDeleteClose();
			})
			.catch(axiosError(this.props.setSysMsg));
	};

	onDeleteClose = (e) => {
		this.setState({ isConfirmModalOpen: false });
	};

	onEditSubmit = (e) => {
		e.preventDefault();
		if (this.state.isModalClosing) // Closing modal, do nothing.
			return false;

		this.setState({ isModalClosing: true });

		const categoryId = this.props._id;
		const req = {
			name: this.state.editCategory
		};

		axios
			.put('/api/categories/' + categoryId, req)
			.then(res => {
				this.props.setSysMsg(res.data);
				this.props.refreshDocuments();
				this.onEditClose();
			})
			.catch(axiosError(this.props.setSysMsg));
	};

	onEditOpen = (e) => {
		e.preventDefault();
		this.setState({
			isEditModalOpen: true,
			editCategory: this.props.name
		});
	};

	onEditClose = (e) => {
		this.setState({ isEditModalOpen: false });
	};

	onAddOpen = (e) => {
		e.preventDefault();
		this.setState({ isAddModalOpen: true });
	};

	onAddSubmit = (e) => {
		e.preventDefault();
		if (this.state.isModalClosing) // Closing modal, do nothing.
			return false;

		this.setState({
			isModalClosing: true
		});

		const req = {
			name: this.state.addSubcategory
		};

		axios
			.post(`/api/categories/${this.props._id}/subcategories` , req)
			.then(res => {
				this.props.setSysMsg(res.data);
				this.props.refreshDocuments();
				this.onAddClose();
			})
			.catch(axiosError(this.props.setSysMsg));
	};

	onAddClose = (e) => {
		this.setState({ isAddModalOpen: false });
	};

	onChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		this.setState({
			[name]: value
		});
	};

	onModalExited = (e) => {
		this.setState({
			isModalClosing: false
		});
	};

	totalDocuments = () => {
		const files = this.props.subcategories.reduce((total, subcategory) => total + subcategory.documents.length, 0);
		return files;
	};

	render() {
		if (this.props.role !== "admin" && this.totalDocuments() === 0) // Don't render empty categories to guests.
			return null;

		return (
			<>
			<div className="category">
				<h1 className="category__heading">
					<div className="category__heading-text">{this.props.name}</div>
					{this.props.role === "admin" &&
						<span className="category__heading-controls">
							<ButtonIcon Icon={PencilOutlineIcon} onClick={this.onEditOpen} title="Přejmenovat kategorii" />
							<ButtonIcon Icon={DeleteOutlineIcon} onClick={this.onDeleteOpen} title="Smazat kategorii" />
						</span>
					}
				</h1>
				{this.props.subcategories.map(subcategory => (
					<Subcategory
						{...subcategory}
						categoryId={this.props._id}
						refreshDocuments={this.props.refreshDocuments}
						role={this.props.role}
						key={subcategory._id}
						setSysMsg={this.props.setSysMsg}
						setLoading={this.props.setLoading}
					/>
				))}
				{this.props.role === "admin" &&
					<div className="h-text-center">
						<ButtonAdd isTransparent={true} label="Přidat podkategorii" onClick={this.onAddOpen} />
					</div>
				}
			</div>
			{this.props.role === "admin" &&
				<>
					<Modal
						open={this.state.isEditModalOpen}
						onClose={this.onEditClose}
						onExited={this.onModalExited}
						title="Upravit kategorii">
						<form action="" onSubmit={this.onEditSubmit}>
							<label htmlFor="editCategory">Název kategorie</label>
							<input type="text" name="editCategory" id="editCategory" className="h-full-width" onChange={this.onChange} defaultValue={this.state.editCategory} />
							<div className="modal__button">
								<button type="submit" className="button">Uložit</button>
							</div>
						</form>
					</Modal>
					<Modal
						open={this.state.isAddModalOpen}
						onClose={this.onAddClose}
						onExited={this.onModalExited}
						title="Přidat podkategorii">
						<form action="" onSubmit={this.onAddSubmit}>
							<label htmlFor="addSubcategory">Název podkategorie</label>
							<input type="text" name="addSubcategory" id="addSubcategory" className="h-full-width" onChange={this.onChange} />
							<div className="modal__button">
								<button type="submit" className="button">Přidat</button>
							</div>
						</form>
					</Modal>
					<ConfirmModal
						open={this.state.isConfirmModalOpen}
						onConfirm={this.onDeleteConfirm}
						onClose={this.onDeleteClose}
						onExited={this.onModalExited}
						title="Smazat kategorii"
						message="Opravdu chcete smazat kategorii včetně všech podkategorií a souborů? Tuto akci nelze vrátit zpět."
					/>
				</>
			}
			</>
		);
	}
}

Category.defaultProps = {
	name: "Kategorie beze jména",
	subcategories: []
};

export default Category;