import React from 'react';

const ButtonIcon = ({ onClick, Icon, size, title }) => {
	return (
		<button className="button button--transparent button--icon" onClick={onClick} title={title}>
			<Icon size={size} />
		</button>
	);
}

export default ButtonIcon;